// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anglia-examinations-js": () => import("./../../../src/pages/AngliaExaminations.js" /* webpackChunkName: "component---src-pages-anglia-examinations-js" */),
  "component---src-pages-brinsbury-college-js": () => import("./../../../src/pages/BrinsburyCollege.js" /* webpackChunkName: "component---src-pages-brinsbury-college-js" */),
  "component---src-pages-chichester-college-js": () => import("./../../../src/pages/ChichesterCollege.js" /* webpackChunkName: "component---src-pages-chichester-college-js" */),
  "component---src-pages-crawley-college-js": () => import("./../../../src/pages/CrawleyCollege.js" /* webpackChunkName: "component---src-pages-crawley-college-js" */),
  "component---src-pages-first-steps-js": () => import("./../../../src/pages/FirstSteps.js" /* webpackChunkName: "component---src-pages-first-steps-js" */),
  "component---src-pages-vacancies-js": () => import("./../../../src/pages/vacancies.js" /* webpackChunkName: "component---src-pages-vacancies-js" */),
  "component---src-pages-worthing-college-js": () => import("./../../../src/pages/WorthingCollege.js" /* webpackChunkName: "component---src-pages-worthing-college-js" */),
  "component---src-templates-all-content-js": () => import("./../../../src/templates/all-content.js" /* webpackChunkName: "component---src-templates-all-content-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */)
}

